import React from 'react';

import type { HTTPMethod } from '@core/types';
import classy from '@core/utils/classy';

import './style.scss';

interface Props {
  className?: string;
  fixedWidth?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  type: HTTPMethod;
}

function getName(type: Props['type']) {
  // Convert to lowercase for class name
  const name = type.toLowerCase();

  switch (name) {
    case 'options':
      return 'optns';
    case 'connect':
      return 'cnnct';
    case 'requestbody':
      return 'rqbdy';
    case 'responses':
      return 'rspns';
    case 'description':
      return 'descr';
    case 'webhook':
      return 'wbhk';
    default:
      return name;
  }
}

const APIMethod = ({ className, fixedWidth, prefix, suffix, type, ...attrs }: Props) => (
  <span
    {...attrs}
    className={classy(
      'rm-APIMethod',
      'APIMethod',
      fixedWidth && 'APIMethod_fixedWidth',
      `APIMethod_${getName(type)}`,
      className,
    )}
    data-testid="http-method"
  >
    {prefix}
    {getName(type)}
    {suffix}
  </span>
);

export default React.memo(APIMethod);
